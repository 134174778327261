import React, { useContext, useEffect, useState } from 'react'

import { AppName } from 'AppSettings'
import Image from 'next/image'

import { ListContext } from 'Clutch/Contexts/ListContext/ListContext'

import Card from 'Clutch/Atoms/Card'
import Skeleton from 'Clutch/Atoms/Skeleton'
import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'

import Frame, { Pluralize } from './CommonBlockStuff'
import styles from './PersonalizedContent.module.scss'

const LISTCOUNTTOSHOW = 4
const PREVIEWIMAGESTOSHOW = 1
const ListBlock = ({ nothingToShowCallback, sectionName }) => {
  const { listContext, getUserLists } = useContext(ListContext)

  const listsWithProducts =
    listContext.lists?.filter((x) => x.articleCount || x.productCount) ?? []

  useEffect(() => {
    getUserLists(true)
  }, [])

  useEffect(() => {
    if (listContext.fullyHydrated && !listsWithProducts.length) {
      nothingToShowCallback()
    }
  }, [listContext.fullyHydrated])

  if (listContext.fullyHydrated && !listsWithProducts.length) {
    return false
  }

  if (!listContext.fullyHydrated) {
    return (
      <Frame>
        <Skeleton fullWidth height={520} />
      </Frame>
    )
  }

  return (
    <Frame title={'Your Lists'} sectionName={sectionName}>
      <div className={styles.cartBlock}>
        {listsWithProducts.slice(0, LISTCOUNTTOSHOW).map((x) => (
          <BuildListCard key={x.id} list={x} />
        ))}
      </div>

      {listContext.lists.some((x) => !x.articleCount && !x.productCount) ||
      listsWithProducts.length > LISTCOUNTTOSHOW ? (
        <TextLink href={`/buildlist`} className={styles.title}>
          See All Lists ({listContext.lists.length})
        </TextLink>
      ) : (
        false
      )}
    </Frame>
  )
}

const BuildListCard = ({ list }) => {
  const getListReps = () => {
    const reps = []

    for (let i = 0, ilen = list.products.length; i < ilen; i++) {
      reps.push({
        imageUrl: list.products[i].imageUrl,
        title: list.products[i].title,
      })
    }

    for (let i = 0, ilen = list.articles.length; i < ilen; i++) {
      reps.push({
        imageUrl: list.articles[i].articleThumbnailUrl,
        title: list.articles[i].title,
      })
    }

    return reps.slice(0, PREVIEWIMAGESTOSHOW)
  }

  return (
    <Card
      className={styles.cartItem}
      noMargin
      noPadding
      href={`/buildlist/${list.shareGuid}`}
      nofollow={true}
    >
      <div className={styles.cartItem}>
        <Typography className={styles.noWrap} font={'bold'} size={1}>
          {list.listName}
        </Typography>
        <div className={styles.listRepresentatives}>
          {getListReps().map((x) => (
            <Image
              key={x.title}
              className={styles.image}
              src={
                x.imageUrl
                  ? x.imageUrl.startsWith('https:')
                    ? x.imageUrl
                    : `https:${x.imageUrl}`
                  : 'https://content.speedwaymotors.com/OtherImages/missingimage2.jpg'
              }
              width={96}
              height={96}
              sizes={'100vw'}
              alt={x.title}
            />
          ))}
        </div>

        <Typography className={styles.noWrap} tone={'subtle'} size={0.75}>
          {list.productCount} Product{Pluralize(list.productCount)}
          {!list.isSavedForLater && (
            <>
              {' '}
              | {list.articleCount} Article{Pluralize(list.articleCount)}
            </>
          )}
        </Typography>
        <Typography tone={'strong'}>{list.totalPriceString}</Typography>
      </div>
    </Card>
  )
}

export default ListBlock
