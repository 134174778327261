import { useEffect, useState } from 'react'

import { useUserStore } from '@speedwaymotors/clutch/Stores/UserStore/UserStore'
import Image from 'next/image'

import GraphQLRequest from 'Clutch/Utilities/GraphQLClient/GraphQLClient'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import Card from 'Clutch/Atoms/Card'
import Skeleton from 'Clutch/Atoms/Skeleton'
import Svg from 'Clutch/Atoms/Svg'
import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'

import Frame from './CommonBlockStuff'
import styles from './PersonalizedContent.module.scss'

const QUERY = `query GetGeolocatedEvents {
    geolocatedTicketEvents(numEvents: 4) {
          eventId 
          __typename
          name
          date
          displayDate
          displayMonth
          displayDay
          description
          callout
          image {
            iconImageUrl
          }
          purchaseurl
          ticketgroupid
          latitude
          longitude    
    }
  }`

const MyRacePassBlock = ({ nothingToShowCallback, sectionName }) => {
  const [events, setEvents] = useState([])

  const hydrated = useUserStore((x) => x.context.hydrated)
  const zipCode = useUserStore((x) => x.context.zipCode)

  useEffect(() => {
    async function getEvents(nothingToShowCallback) {
      const data = await GraphQLRequest('/graphql/recommendations', QUERY)
      if (data.geolocatedTicketEvents.length) {
        setEvents(data.geolocatedTicketEvents)
        return
      }
      nothingToShowCallback()
    }
    if (zipCode) {
      getEvents(nothingToShowCallback)
    }
  }, [hydrated, zipCode])

  if (!events.length) {
    return (
      <Frame>
        <Skeleton fullWidth height={520} />
      </Frame>
    )
  }
  return (
    <Frame sectionName={sectionName}>
      <div className={styles.eventTitle}>
        <Image
          src="https://content-qa.speedway2.com/HomePageImages/MyRacePass.png"
          width={90}
          height={54}
          alt={'My Race Pass Image'}
        ></Image>
        <Typography size={0.875} font="bold">
          Get the
          <TextLink
            targetBlank
            nofollow
            className={styles.appLink}
            href="https://www.myracepass.com/experience/app"
            intent="action"
          >
            {' '}
            MRP App
            <Svg
              className={styles.inLineSvg}
              icon={'external_link'}
              title={'MyRacePass external link'}
              intent={'action'}
              size={0.75}
            />
          </TextLink>{' '}
          to follow your favorite tracks, series and drivers and stay up to date
          with all the racing action.
        </Typography>
      </div>
      <Typography size={1.125} font="bold">
        Events Near You
      </Typography>
      {events.map((event, i) => {
        return <EventCard key={`mrp-event-${i}`} event={event} />
      })}
    </Frame>
  )
}

const EventCard = ({ event }) => {
  const segmentEvent = createClickedSegmentEvent('MyRacePassEvent')
  return (
    <Card
      segmentEvent={segmentEvent}
      noMargin
      noPadding
      nofollow
      targetBlank
      href={event.purchaseurl}
    >
      <div className={styles.eventCard}>
        <div className={styles.dateContainer}>
          <Typography size={0.875} font="bold" tone="subtle">
            {event.displayMonth}
          </Typography>
          <Typography size={1.25} font="bold">
            {event.displayDay}
          </Typography>
        </div>
        <div className={styles.descriptionContainer}>
          <div>
            <Typography className={styles.description} size={0.875} font="bold">
              {event.description}
            </Typography>
          </div>
          <Typography className={styles.description} size={0.875}>
            {event.name}
          </Typography>
        </div>
        <Svg
          className={styles.svg}
          icon={'external_link'}
          title={'MyRacePass external link'}
          tone={'superfluous'}
          size={0.75}
        />
      </div>
    </Card>
  )
}

export default MyRacePassBlock
