import { useEffect } from 'react'

import { useUserStore } from '@speedwaymotors/clutch/Stores/UserStore/UserStore'
import ImpressionBoundary from 'Clutch/UtilityComponents/ImpressionBoundary'

import useSegmentLeadCaptureEvent from 'Clutch/Hooks/useSegment/segmentSpecificHooks/useSendLeadCaptureEvent'

import CalloutCard from 'Clutch/Organisms/CalloutCard/CalloutCard'

import Skeleton from 'Clutch/Atoms/Skeleton'

import { useHomePage } from '../../providers/HomePageProvider'
import Frame, { BuildImpressionSectionName } from './CommonBlockStuff'
import styles from './PersonalizedContent.module.scss'

const SignupBlock = ({ nothingToShowCallback, sectionName }) => {
  const { sendSmsAndEmailEvent } = useSegmentLeadCaptureEvent()
  const { audience } = useHomePage()
  const hydrated = useUserStore((x) => x.context.hydrated)
  const isRegistered = useUserStore((x) => x.context.isRegistered)

  useEffect(() => {
    if (hydrated && isRegistered) {
      nothingToShowCallback()
    }
  }, [hydrated, isRegistered])

  if (hydrated && isRegistered) {
    return false
  }
  if (!hydrated) {
    return (
      <Frame>
        <Skeleton fullWidth height={520} />
      </Frame>
    )
  }
  const sectionId = BuildImpressionSectionName(sectionName)
  return (
    <>
      <ImpressionBoundary
        sectionName={sectionId}
        additionalViewedFuncs={[
          () => sendSmsAndEmailEvent('Impression', sectionId),
        ]}
        additionalClickedFuncs={[
          () => sendSmsAndEmailEvent('Started', sectionId),
        ]}
        innerClassName={styles.impressionInnerCard}
        audience={audience}
      >
        <CalloutCard
          title={'$10 Off Your First Order'}
          subtitle={'Sign up and receive the best deals and expert information'}
          button={{ url: '/emailsignup', text: 'Sign Up' }}
          darkened={true}
          noSubtitleOnMobile={true}
          className={styles.signupCard}
          noMargin
          image={
            'https://content.speedwaymotors.com/OtherImages/tri-column-signup-bkg.png'
          }
        />
      </ImpressionBoundary>
    </>
  )
}

export default SignupBlock
