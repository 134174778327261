import React from 'react'

import { FitmentStateProvider } from '../../providers/FitmentStateProvider'
import HeroSection from './HeroSection'

const HeroSectionContent = ({ audience }) => {
  return (
    <>
      <FitmentStateProvider>
        <HeroSection audience={audience.toLowerCase()} showGradient={false} />
      </FitmentStateProvider>
    </>
  )
}

export default HeroSectionContent
