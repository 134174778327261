import { useUserStore } from '@speedwaymotors/clutch/Stores/UserStore/UserStore'
import Image from 'next/image'

import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import Button from 'Clutch/Molecules/Button'

import Skeleton from 'Clutch/Atoms/Skeleton'
import Typography from 'Clutch/Atoms/Typography'

import { isCawWeek } from '../../../utils/cawUtils'
import Frame from '../CommonBlockStuff'
import styles from './LoyaltyBlock.module.scss'

const LoyaltyBlock = ({ nothingToShowCallback, sectionName }) => {
  const hydrated = useUserStore((x) => x.context.hydrated)
  const isLoyaltyBetaUser = useUserStore((x) => x.context.isLoyaltyBetaUser)
  const isLoyaltyRegisteredUser = useUserStore(
    (x) => x.context.isLoyaltyRegisteredUser,
  )

  if (!hydrated)
    return (
      <Frame>
        <Skeleton fullWidth height={520} />
      </Frame>
    )

  if (!isLoyaltyBetaUser || isLoyaltyRegisteredUser) nothingToShowCallback()

  const isCAW = isCawWeek()

  return (
    <Frame sectionName={sectionName} inverseTheme={true}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <Image
            src="https://content.speedwaymotors.com/HomePageImages/SWM-rewards.png"
            width={500}
            height={200}
            className={styles.img}
            alt={'Speedway Loyalty Rewards Image'}
          ></Image>

          <Typography
            className={styles.text}
            tone={'contrast'}
            size={1.5}
            font={'bold'}
            inline
          >
            {isCAW
              ? 'Get an Extra $10 Off This Week'
              : 'Earn Points on Every Dollar You Spend!'}
          </Typography>
          <Typography
            className={styles.text}
            tone={'regular'}
            size={1}
            font={'subtle'}
            inline
          >
            Join our FREE Rewards Program!
          </Typography>
          <Button
            className={styles.button}
            brand={'secondary'}
            size={'large'}
            href={
              isCAW
                ? '/shop/2024-customer-appreciation-week~16777216-724369'
                : '/pages/rewards'
            }
            segmentEvent={createClickedSegmentEvent('LoyaltySignup')}
            fill
          >
            <Typography size={1} font={'bold'}>
              Start Earning Today!
            </Typography>
          </Button>
        </div>
      </div>
    </Frame>
  )
}

export default LoyaltyBlock
