import React, { useEffect, useState } from 'react'

import { GetUserGuid } from '@speedwaymotors/clutch/Utilities/CookieUtilties/UserCookieUtilities'
import Image from 'next/image'

import GraphQLRequest from 'Clutch/Utilities/GraphQLClient/GraphQLClient'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import useCart from 'Clutch/Hooks/useCart/useCart'

import Button from 'Clutch/Molecules/Button'

import Card from 'Clutch/Atoms/Card'
import Skeleton from 'Clutch/Atoms/Skeleton'
import Typography from 'Clutch/Atoms/Typography'

import Frame, { Pluralize } from './CommonBlockStuff'
import styles from './PersonalizedContent.module.scss'

const QUERY = `query
HomePagePersonalizedCart($webUserGuid: String){
    shoppingCart(webUserGuid: $webUserGuid) {
      cartSubTotal {
        stringValue
      }
      numProducts
      lineItems {
        skuImageUrl
        skuTitle
      }
    }
}`

const PRODUCTSTOSHOW = 3

const CartBlock = ({ nothingToShowCallback, sectionName }) => {
  const { cart } = useCart()
  const [fullCart, setFullCart] = useState('skeleton')

  useEffect(() => {
    if (cart.itemCount && cart.shoppingCartId) {
      FetchFullCart()
    } else if (cart.hydrated) {
      setFullCart(false)
      nothingToShowCallback()
    }
  }, [cart.itemCount, cart.shoppingCartId, cart.hydrated])

  const FetchFullCart = async () => {
    try {
      const variables = {
        webUserGuid: GetUserGuid(),
      }

      const data = await GraphQLRequest('/graphql/checkout', QUERY, variables)

      setFullCart(data.shoppingCart)
    } catch (error) {
      console.error(error)
      setFullCart(false)
      nothingToShowCallback()
    }
  }

  if (!fullCart) {
    return false
  }
  if (fullCart === 'skeleton') {
    return (
      <Frame>
        <Skeleton fullWidth height={520} />
      </Frame>
    )
  }
  return (
    <Frame title={'Your Cart'} sectionName={sectionName}>
      <Card noMargin href={`/shoppingCart`} nofollow={true}>
        <div className={styles.cartBlock}>
          {fullCart.lineItems.slice(0, PRODUCTSTOSHOW).map((x) => (
            <div key={x.skuTitle} className={styles.cartItem}>
              <Image
                key={x.skuTitle}
                className={styles.image}
                src={
                  x.skuImageUrl
                    ? x.skuImageUrl.startsWith('https:')
                      ? x.skuImageUrl
                      : `https:${x.skuImageUrl}`
                    : 'https://content.speedwaymotors.com/OtherImages/missingimage2.jpg'
                }
                width={128}
                height={128}
                sizes={'100vw'}
                alt={x.skuTitle}
              />
              <Typography className={styles.cartItemTitle}>
                {x.skuTitle}
              </Typography>
            </div>
          ))}
          <div className={styles.cartItem}>
            <Typography className={styles.noWrap} size={0.875} font={'bold'}>
              {fullCart.numProducts} Item{Pluralize(fullCart.numProducts)} |{' '}
              {fullCart.cartSubTotal.stringValue}
            </Typography>
            <Button
              intent={'positive'}
              size={'small'}
              fill
              onClick={() => (window.location.href = `/shoppingCart`)}
              segmentEvent={createClickedSegmentEvent('GoToCart')}
              type={'button'}
              text={'Go To Cart'}
            />
          </div>
        </div>
      </Card>
    </Frame>
  )
}

export default CartBlock
