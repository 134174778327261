import { useEffect, useState } from 'react'

import { useUserStore } from '@speedwaymotors/clutch/Stores/UserStore/UserStore'
import { GetUserGuid } from '@speedwaymotors/clutch/Utilities/CookieUtilties/UserCookieUtilities'

import GraphQLRequest from 'Clutch/Utilities/GraphQLClient/GraphQLClient'

import ProductCard from 'Clutch/Components/ProductCard'

import Card from 'Clutch/Atoms/Card'
import Skeleton from 'Clutch/Atoms/Skeleton'

import Frame from './CommonBlockStuff'
import styles from './PersonalizedContent.module.scss'

const QUERY = `query getRecentOrderedProducts($webUserGuid: String) {
    recentOrderedProducts(webUserGuid: $webUserGuid) {
        skuVariant
        skuBase
        publicSkuBaseDetails (webUserGuid: $webUserGuid) {
            pricing {
                price
                salePriceString
                strikeThroughPrice
                }
            rating
            reviewCount
            displayTitle
            productPageUrl
            imageLink
        }
    }
}`

const BuyItAgainBlock = ({ nothingToShowCallback, sectionName }) => {
  const hydrated = useUserStore((x) => x.context.hydrated)

  const [products, setProducts] = useState('skeleton')

  useEffect(() => {
    FetchBuyItAgainProducts()
  }, [hydrated])

  const FetchBuyItAgainProducts = async () => {
    try {
      const variables = {
        webUserGuid: GetUserGuid(),
      }

      const data = await GraphQLRequest(
        '/graphql/recommendations',
        QUERY,
        variables,
      )
      setProducts(data.recentOrderedProducts)
    } catch (error) {
      console.error(error)
      setProducts(false)
      nothingToShowCallback()
    }
  }

  if (!products.length) {
    nothingToShowCallback()
  }
  if (products === 'skeleton' || !products.length) {
    return (
      <Frame>
        <Skeleton fullWidth height={520} />
      </Frame>
    )
  }
  return (
    <Frame title={'Buy It Again'} sectionName={sectionName}>
      {products.slice(0, 3).map((x) => {
        return (
          <Card
            noMargin
            noPadding
            nofollow
            href={x.publicSkuBaseDetails.productPageUrl}
            className={styles.orderTitle}
            key={x.skuVariant}
          >
            <ProductCard
              key={x.skuVariant}
              dataTestId={'buy_it_again'}
              displayTitle={x.publicSkuBaseDetails.displayTitle}
              productPageUrl={x.publicSkuBaseDetails.productPageUrl}
              imageUrl={x.publicSkuBaseDetails.imageLink}
              rating={x.publicSkuBaseDetails.rating}
              reviewCount={x.publicSkuBaseDetails.reviewCount ?? 0}
              salePriceString={x.publicSkuBaseDetails.pricing.salePriceString}
              horizontal={true}
              skuBaseNumber={x.skuBase}
              skuVariant={x.skuVariant}
              displayWriteAReview
              skuBaseDetails={x.publicSkuBaseDetails}
            />
          </Card>
        )
      })}
    </Frame>
  )
}

export default BuyItAgainBlock
