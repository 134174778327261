import { useEffect, useState } from 'react'

import { useUserStore } from '@speedwaymotors/clutch/Stores/UserStore/UserStore'

import OrderSummaryCard from 'Clutch/Organisms/OrderSummaryCard'

import Skeleton from 'Clutch/Atoms/Skeleton'
import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'

import Frame from './CommonBlockStuff'

const fetchRecentOrders = async () => {
  const res = await fetch(`api/orders/order/recentorders`, {
    method: 'GET',
  })

  if (!res.ok) {
    return null
  }

  return await res.json()
}

const OrdersBlock = ({ nothingToShowCallback, sectionName }) => {
  const hydrated = useUserStore((x) => x.context.hydrated)
  const [orders, setOrders] = useState('skeleton')
  useEffect(() => {
    if (hydrated) {
      hydrateRecentOrders()
    }
  }, [hydrated])
  const hydrateRecentOrders = async () => {
    try {
      const data = await fetchRecentOrders()
      if (data && data.orderSummaries) {
        setOrders(data.orderSummaries)
      } else {
        setOrders([])
      }
    } catch (error) {
      console.error(error)
      setOrders([])
    }
  }
  if (orders === 'skeleton') {
    return (
      <Frame>
        <Skeleton fullWidth height={520} />
      </Frame>
    )
  }

  const today = new Date()
  const sixtyDaysAgo = new Date(today)
  sixtyDaysAgo.setDate(today.getDate() - 60)
  const recentOrders = orders.filter(
    (order) => new Date(order.orderDate) >= sixtyDaysAgo,
  )

  if (recentOrders.length === 0) {
    nothingToShowCallback()
  }

  return (
    <Frame title={'Recent Orders'} sectionName={sectionName}>
      {recentOrders.map((order) => (
        <OrderSummaryCard
          key={order.orderNumber}
          order={order}
          isCondensed={true}
        />
      ))}
      <TextLink href={`/Account/OrderHistory`}>
        <Typography size={0.875} intent={'action'}>
          See All Orders
        </Typography>
      </TextLink>
    </Frame>
  )
}

export default OrdersBlock
