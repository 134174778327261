const personalizedSubSectionTypes = {
  loyalty: 'LOYALTY',
  recent_history: 'RECENTHISTORY',
  cart: 'CART',
  buildlist: 'BUILDLIST',
  signup: 'SIGNUP',
  account: 'ACCOUNT',
  myracepass: 'MYRACEPASS',
  buyitagain: 'BUYITAGAIN',
  orders: 'ORDERS',
}

export default personalizedSubSectionTypes
