import ImpressionBoundary from 'Clutch/UtilityComponents/ImpressionBoundary'
import ClassNames from 'classnames'

import Panel from 'Clutch/Atoms/Panel'
import Typography from 'Clutch/Atoms/Typography'

import { useHomePage } from '../../providers/HomePageProvider'
import styles from './PersonalizedContent.module.scss'

const Frame = ({ children, title, sectionName, inverseTheme }) => {
  const { audience } = useHomePage()

  if (!sectionName) {
    return (
      <Panel
        layer={0}
        className={styles.block}
        data-testid={`Personalized_Section_skeleton`}
      >
        {children}
      </Panel>
    )
  }

  return (
    <ImpressionBoundary
      sectionName={BuildImpressionSectionName(sectionName)}
      innerClassName={styles.impressionInner}
      audience={audience}
    >
      <Panel
        layer={0}
        inverseTheme={inverseTheme}
        className={ClassNames(styles.block, styles.fillHeight)}
        data-testid={`Personalized_Section_${sectionName}`}
      >
        {title && (
          <Typography
            as={'h3'}
            font={'bold'}
            className={styles.title}
            size={1.25}
          >
            {title}
          </Typography>
        )}
        {children}
      </Panel>
    </ImpressionBoundary>
  )
}

const Pluralize = (count) => {
  return count === 1 ? '' : 's'
}

const BuildImpressionSectionName = (sectionName) => {
  return `homepage_personalized_${sectionName}`
}

export default Frame

export { Pluralize, BuildImpressionSectionName }
