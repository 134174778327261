import { useEffect, useState } from 'react'

import { GetUserGuid } from '@speedwaymotors/clutch/Utilities/CookieUtilties/UserCookieUtilities'

import GraphQLRequest from 'Clutch/Utilities/GraphQLClient/GraphQLClient'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import useRecentHistory from 'Clutch/Hooks/useRecentHistory'

import ProductCard from 'Clutch/Components/ProductCard'

import LandingPageLink from 'Clutch/Molecules/LandingPageLink'

import Card from 'Clutch/Atoms/Card'
import Skeleton from 'Clutch/Atoms/Skeleton'

import Frame from './CommonBlockStuff'
import styles from './PersonalizedContent.module.scss'

const QUERY = `query GetSkuBases($skuBaseNumbers: [String], $webUserGuid: String){
      skuBases (skuBaseNumbers: $skuBaseNumbers, webUserGuid: $webUserGuid){
        skuBaseNumber
        displayTitle
        pricing {
          salePriceString
          strikeThroughPrice
          salePrice
        }
        rating
        reviewCount
        availability
        productPageUrl
        brand
        segmentCategorization
      }
    }`

const COUNTEACHTODISPLAY = 2

const RecentHistoryBlock = ({ nothingToShowCallback, sectionName }) => {
  const [recentPrp, setRecentPrp] = useState('skeleton')
  const [recentPdp, setRecentPdp] = useState('skeleton')

  useEffect(() => {
    async function Hydrate() {
      const prp_history =
        useRecentHistory
          .GetRecentHistory('Prp', 'title')
          ?.slice(0, COUNTEACHTODISPLAY) ?? []
      const pages_history =
        useRecentHistory
          .GetRecentHistory('Pages', 'title')
          ?.slice(0, COUNTEACHTODISPLAY) ?? []

      let combined_history = []
      combined_history = combined_history.concat(prp_history)
      combined_history = combined_history.concat(pages_history)

      const ordered_history = combined_history.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })

      setRecentPrp(ordered_history?.slice(0, COUNTEACHTODISPLAY) ?? [])

      const recentHistory =
        useRecentHistory
          .GetRecentHistory('Pdp', 'skuBaseNumber')
          ?.slice(0, COUNTEACHTODISPLAY) ?? []
      FetchRecentHistoryProducts(recentHistory)
    }
    if (typeof window != 'undefined') {
      Hydrate()
    }
  }, [])

  const FetchRecentHistoryProducts = async (recentHistory) => {
    try {
      const skuBaseNumbers = recentHistory.map((x) => x.skuBaseNumber)
      const variables = {
        webUserGuid: GetUserGuid(),
        skuBaseNumbers: skuBaseNumbers?.filter((x) => x),
      }

      const data = await GraphQLRequest('/graphql/products', QUERY, variables)
      for (let i = 0; i < recentHistory.length; i++) {
        const matchingProduct = data.skuBases.find(
          (x) => x.skuBaseNumber === recentHistory[i].skuBaseNumber,
        )
        if (!matchingProduct) {
          continue
        }
        recentHistory[i].salePriceString =
          matchingProduct.pricing.salePriceString
        recentHistory[i].reviewCount = matchingProduct.reviewCount
        recentHistory[i].rating = matchingProduct.rating
      }
      setRecentPdp(recentHistory)
    } catch (error) {
      console.error(`${error}`)
      setRecentPdp(false)
      nothingToShowCallback()
    }
  }

  if (recentPdp === 'skeleton' || recentPrp === 'skeleton') {
    return (
      <Frame>
        <RecentHistorySkeleton />
      </Frame>
    )
  }

  if (!recentPdp.length && !recentPrp.length) {
    nothingToShowCallback()
    return false
  }

  const convertHistoryToLink = (history) => {
    return { ...history, displayValue: history.title, nofollow: true }
  }

  return (
    <Frame title={'Pick Up Where You Left Off'} sectionName={sectionName}>
      <>
        {recentPdp.map((x) => {
          return (
            <Card
              noMargin
              noPadding
              nofollow
              href={x.url}
              className={styles.orderTitle}
              key={x.skuVariant}
            >
              <ProductCard
                dataTestId={'recent_history'}
                displayTitle={x.title}
                productPageUrl={x.url}
                brand={x.brand}
                category={x.segmentCategorization}
                imageUrl={x.imageUrl}
                rating={x.rating}
                reviewCount={x.reviewCount}
                salePriceString={x.salePriceString}
                horizontal={true}
                skuBaseNumber={x.skuBaseNumber}
                skuVariant={x.skuVariant}
              />
            </Card>
          )
        })}
        {recentPrp.map((x) => (
          <LandingPageLink key={x.url} link={convertHistoryToLink(x)} />
        ))}
        <LandingPageLink
          link={{
            displayValue: 'Recommendations For You',
            url: '/recommendations',
            nofollow: true,
          }}
          segmentEvent={createClickedSegmentEvent(
            '`Recommendations For You` via RecentHistoryBlock',
          )}
        />
      </>
    </Frame>
  )
}

const RecentHistorySkeleton = () => {
  const rows = []
  rows.push(<Skeleton fullWidth height={30} key={`history_skeleton_title`} />)
  for (let i = 0; i < COUNTEACHTODISPLAY; i++) {
    rows.push(
      <Skeleton fullWidth height={100} key={`history_skeleton_tall_${i}`} />,
    )
  }
  for (let i = 0; i < COUNTEACHTODISPLAY; i++) {
    rows.push(
      <Skeleton fullWidth height={60} key={`history_skeleton_short_${i}`} />,
    )
  }
  rows.push(
    <Skeleton
      fullWidth
      height={60}
      key={`history_skeleton_recommendation_for_you`}
    />,
  )

  return <>{rows}</>
}

export default RecentHistoryBlock
