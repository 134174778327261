import classNames from 'classnames'

import Gutters from 'Clutch/Atoms/Gutters'
import Panel from 'Clutch/Atoms/Panel'
import Svg from 'Clutch/Atoms/Svg'

import styles from './ValueAdds.module.scss'

const ValueAdds = () => {
  const items = [
    {
      svg: 'Expert-tech',
      title: 'Expert Tech Advice',
      description: "We've been there",
    },
    {
      svg: 'inStock-ship',
      title: 'In Stock & Ready to Ship',
      description: 'Search thousands of Parts & Accessories',
    },
    {
      svg: 'fast_shipping',
      title: 'Fast & Free Shipping',
      description: 'We ship fast, all over the world',
    },
  ]
  return (
    <Panel layer={0} inverseTheme className={styles.background}>
      <Gutters className={styles.container} noVerticalPadding>
        {items.map((item) => {
          return (
            <div className={styles.valueAdd} key={item.title}>
              <Svg
                className={styles.icon}
                icon={item.svg}
                tone={'contrast'}
                size={4}
              />
              <div className={styles.textContainer}>
                <p className="p p-1 p-color-contrast p-bold">{item.title}</p>
              </div>
              <div className={styles.textContainer}>
                <p className="p p-0_75  p-color-contrast">{item.description}</p>
              </div>
            </div>
          )
        })}
      </Gutters>
    </Panel>
  )
}

export default ValueAdds
